<template lang="pug">
  //- todo: remove <p> tag
  .title-rich
    slot
</template>

<script>
export default {
  name: 'TitleRich'
}
</script>

<style>
.title-rich{
  & em {
    @apply not-italic;
    font-size: calc(24/34 * 1em);
  }
  & strong, & b {
    @apply font-normal italic text-current;
  }
}
</style>
