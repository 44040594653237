<template lang="pug">
  footer.app-footer.bg-yellow.p-20.flex
    app-footer-menu
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style>
</style>
