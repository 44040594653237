<template lang="pug">
  #app.text-14.relative.min-h-screen.flex.flex-col.bold-is-teal
    app-header

    .flex-1
      transition(name="pagefade", @after-leave="$root.$emit('afterRouteLeave')")
        router-view(:key="rtKey")

    app-footer.md_hidden

    cookie-banner
    newsletter-pop-up

    //- debug grid
    .fixed.overlay.flex.md_px-20.pointer-events-none(style="z-index:1000", v-if="$route.hash === '#debug'")
      .px-20.flex.flex-1.md_hidden(v-for="n in 2")
        .w-full(style="background:rgba(255,0,0,0.1)")
      .px-20.flex.flex-1.hidden.md_flex(v-for="n in 12")
        .w-full(style="background:rgba(255,0,0,0.1)")
</template>

<script>
import '@/style/_root.css'
import 'flickity/css/flickity.css'
// import 'plyr/dist/plyr.css' // broken!
import AppHeader from '@/components/globals/AppHeader'
import NewsletterPopUp from '@/components/NewsletterPopUp'
import throttle from 'lodash/throttle'

export default {
  name: 'App',
  metaInfo: {
    titleTemplate: (pageTitle) => {
      const siteTitle = 'The Roberts Institute of Art'
      return pageTitle ? `${pageTitle} | ${siteTitle}` : `${siteTitle}`
    },
    link: [
      { rel: 'stylesheet', href: 'https://unpkg.com/vue-plyr/dist/vue-plyr.css' }
    ]
  },
  components: { AppHeader, NewsletterPopUp },
  computed: {
    rtKey () {
      return this.$route.name?.split('-')[0]
    }
  },
  methods: {
    onResize: throttle(function () {
      this.$store.commit('SET_WIN_W')
    }, 10)
  },
  created () {
    this.$store.dispatch('getGlobals')
    window.addEventListener('resize', this.onResize)
  }
}
</script>

<style>
body {
  @apply font-sans;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagefade-leave-active{ transition: opacity 200ms; }
.pagefade-enter-active{ transition: opacity 500ms 200ms; }
.pagefade-leave-to,
.pagefade-enter{ opacity:0; }
</style>
