<template lang="pug">
  <svg viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M6.88 0H0L22.24 20L0 40H6.88L28 20L6.88 0Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgChevronRight'
}
</script>

<style>
</style>
