<template lang="pug">
  <svg width="31" height="25" viewBox="0 0 31 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M31 0H26.9171L15.5756 10.7988L4.23415 0H0L13.761 12.426L0 25H4.23415L15.5756 14.2012L26.9171 25H31L17.3902 12.426L31 0Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SVG-X'
}
</script>

<style>
</style>
