<template lang="pug">
  .video-plyr-js.relative.cursor-pointer
    //- vimeo/youtube
    template(v-if="embed")
      <div ref="player" class="plyr__video-embed" v-html="embed"></div>

    //- <video>
    template(v-else-if="video")
      <video ref="player" :src="video.url" playsinline controls :data-poster="video.poster" :muted="autoplayMuted" :autoplay="autoplayMuted" :loop="loop"></video>

    //- play icon / overlay
    //- * blocks auto-show controls before first play via .pointer-events-none
    .absolute.overlay.flex.items-center.justify-center(v-if="player && !autoplayMuted", :style="!playing ? 'background:rgba(0,0,0,0.1)' : ''", :class="{'pointer-events-none': playing !== undefined}")
      button.p-12.transition-opacity.duration-200.pointer-events-auto.focus_outline-none(@click="player.play()", :class="{'opacity-0': playing}")
        svg-play-triangle.h-60.w-60.text-white
</template>

<script>
import Plyr from 'plyr'
export default {
  name: 'VideoPlyrJs',
  props: {
    embed: String,
    video: Object,
    autoplayMuted: { type: Boolean, default: false },
    ratio: { type: String, default: null },
    loop: Boolean,
    storageKey: String
  },
  data () {
    return {
      player: null,
      playing: undefined
    }
  },
  computed: {
    controls () {
      return this.autoplayMuted ? ['mute']
        : ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
    }
  },
  methods: {
    init () {
      // full controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
      this.player = new Plyr(this.$refs.player, {
        controls: this.controls,
        muted: this.autoplayMuted,
        hideControls: !this.autoplayMuted,
        ratio: this.ratio,
        youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 },
        // local storage?
        storage: {
          enabled: typeof this.storageKey === 'string',
          key: `plyr_${this.storageKey}`
        }
      })
      // hide controls at start
      this.player.on('ready', this.onReady)
      this.player.on('controlsshown', e => this.playing === undefined && e.detail.plyr.toggleControls())
      // listen for play/pause
      this.player.on('playing', () => { this.playing = true })
      this.player.on('pause', () => { this.playing = false })
      //
      this.player.on('ended', () => this.$emit('ended'))
    },
    onReady (e) {
      if (this.autoplayMuted) {
        this.play()
      } else {
        e.detail.plyr.toggleControls()
      }
    },
    play () {
      return this.player?.play()
    },
    pause () {
      return this.player?.pause()
    }
  },
  watch: {
    playing (playing) {
      return playing ? this.$emit('playing') : this.$emit('pause')
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style>
.video-plyr-js{
  & .plyr__video-wrapper{
    /* sometimes thin black lines because pixel rounding from aspect ratio (eg 56.5%) */
    /* plyr.js should use calc... calc(100% * 9/16) */
    transform: scale(1.01,1.01);
  }
}
/*.video-plyr-js {
  & .video-plyr-js--controls-bg-none {
    & .plyr__controls {
      background: none;
    }
  }
}*/
</style>
