<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 615.6 22.8" style="enable-background:new 0 0 615.6 22.8;" xml:space="preserve">
    <g>
      <g>
        <polygon points="615.6,0.6 595.3,0.6 595.3,3.1 604.1,3.1 604.1,22.2 606.8,22.2 606.8,3.1 615.6,3.1    "/>
        <path d="M576.4,3.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9V3.1z
           M591.3,22.2l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8h-10.6v21.6
          h2.8v-9.7h3.3l7.9,9.7H591.3z"/>
        <path d="M551.8,11.9l4-9.1h0.2l4,9.1H551.8z M564.7,22.2h3.1L558,0.6h-4.1l-9.8,21.6h3.1l3.5-7.7h10.6L564.7,22.2z"/>
        <polygon points="514.2,22.2 517,22.2 517,12.6 529.4,12.6 529.4,10.1 517,10.1 517,3.1 530,3.1 530,0.6 514.2,0.6    "/>
        <path d="M494.3,20.2c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2c0-1.1,0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6
          c4.9,0,8.5,2.2,9.8,5.6c0.4,1,0.6,2.1,0.6,3.2c0,1.1-0.2,2.2-0.6,3.2C502.8,18.1,499.2,20.2,494.3,20.2 M494.3,22.8
          c6.3,0,10.9-2.8,12.5-7.2c0.5-1.3,0.7-2.7,0.7-4.1c0-1.4-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2
          c-0.5,1.3-0.7,2.7-0.7,4.1c0,1.4,0.2,2.8,0.7,4.1C483.4,20.1,488.1,22.8,494.3,22.8"/>
      </g>
      <g>
        <polygon points="448.6,22.2 464.4,22.2 464.4,19.7 451.4,19.7 451.4,12.6 464.1,12.6 464.1,10.1 451.4,10.1 451.4,3.1 464.4,3.1
          464.4,0.6 448.6,0.6     "/>
        <polygon points="443.1,0.6 422.8,0.6 422.8,3.1 431.6,3.1 431.6,22.2 434.3,22.2 434.3,3.1 443.1,3.1    "/>
        <path d="M407.6,22.8c6.2,0,10-3.5,10-9.3V0.6h-2.9v12.9c0,4.2-2.7,6.7-7.2,6.7c-4.4,0-7.1-2.6-7.1-6.7V0.6h-2.9v12.9
          C397.5,19.3,401.3,22.8,407.6,22.8"/>
        <polygon points="392.3,0.6 372,0.6 372,3.1 380.8,3.1 380.8,22.2 383.5,22.2 383.5,3.1 392.3,3.1    "/>
        <rect x="363.7" y="0.6" width="2.8" height="21.6"/>
        <polygon points="358.2,0.6 337.8,0.6 337.8,3.1 346.6,3.1 346.6,22.2 349.4,22.2 349.4,3.1 358.2,3.1    "/>
        <path d="M324.1,22.8c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3c0-1.1-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
          c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5c0-0.6,0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9
          V7.2c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
          c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5c0,0.6-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5v-0.2h-2.9
          v0.4C314.7,20.2,318.4,22.8,324.1,22.8z"/>
        <polygon points="286.9,22.2 289.7,22.2 289.7,3.7 303.2,22.2 307.5,22.2 307.5,0.6 304.7,0.6 304.7,19.1 291.2,0.6 286.9,0.6
          "/>
        <rect x="275.9" y="0.6" width="2.8" height="21.6"/>
      </g>
      <g>
        <path d="M248.1,22.8c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3c0-1.1-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
          c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5c0-0.6,0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9
          V7.2c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
          c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5c0,0.6-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5v-0.2h-2.9
          v0.4C238.6,20.2,242.4,22.8,248.1,22.8z"/>
        <polygon points="234.6,0.6 214.3,0.6 214.3,3.1 223.1,3.1 223.1,22.2 225.8,22.2 225.8,3.1 234.6,3.1    "/>
        <path d="M195.4,3.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9V3.1z
           M210.3,22.2l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8h-10.6v21.6
          h2.8v-9.7h3.3l7.9,9.7H210.3z"/>
        <polygon points="169.5,22.2 185.3,22.2 185.3,19.7 172.3,19.7 172.3,12.6 185,12.6 185,10.1 172.3,10.1 172.3,3.1 185.3,3.1
          185.3,0.6 169.5,0.6     "/>
        <path d="M158.9,18c-0.7,1.1-2,1.7-4,1.7h-7.7v-7.1h7.7c1.9,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.8
          C159.4,16.8,159.3,17.5,158.9,18z M147.2,3.1h7.7c2,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.9c0,0.7-0.2,1.3-0.5,1.8
          c-0.6,1.1-2,1.7-4,1.7h-7.7V3.1z M157.5,11.4L157.5,11.4c1.9-0.3,3.4-1.2,4.1-2.6c0.4-0.7,0.6-1.5,0.6-2.5c0-1.1-0.2-2-0.7-2.8
          c-1-1.8-3.2-2.9-6.4-2.9h-10.7v21.6h10.7c3.2,0,5.3-1.1,6.4-2.9c0.5-0.8,0.7-1.7,0.7-2.8c0-1-0.2-1.8-0.6-2.5
          C160.8,12.6,159.4,11.6,157.5,11.4"/>
        <path d="M124.5,20.2c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2c0-1.1,0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6
          s8.5,2.2,9.8,5.6c0.4,1,0.6,2.1,0.6,3.2c0,1.1-0.2,2.2-0.6,3.2C133,18.1,129.4,20.2,124.5,20.2 M124.5,22.8
          c6.3,0,10.9-2.8,12.5-7.2c0.5-1.3,0.7-2.7,0.7-4.1c0-1.4-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2
          c-0.5,1.3-0.7,2.7-0.7,4.1c0,1.4,0.2,2.8,0.7,4.1C113.6,20.1,118.3,22.8,124.5,22.8"/>
        <path d="M91.5,3.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9V3.1z
           M106.4,22.2l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8H88.7v21.6
          h2.8v-9.7h3.3l7.9,9.7H106.4z"/>
      </g>
      <g>
        <polygon points="54.7,22.2 70.4,22.2 70.4,19.7 57.5,19.7 57.5,12.6 70.1,12.6 70.1,10.1 57.5,10.1 57.5,3.1 70.4,3.1 70.4,0.6
          54.7,0.6    "/>
        <polygon points="43.6,0.6 43.6,10.1 28.6,10.1 28.6,0.6 25.8,0.6 25.8,22.2 28.6,22.2 28.6,12.6 43.6,12.6 43.6,22.2 46.4,22.2
          46.4,0.6    "/>
        <polygon points="20.3,0.6 0,0.6 0,3.1 8.8,3.1 8.8,22.2 11.5,22.2 11.5,3.1 20.3,3.1    "/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SvgLogoMasthead'
}
</script>

<style>
</style>
