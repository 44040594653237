<template lang="pug">
  //- TODO make router compatible
  a.flex-link(:href="linkUrl", :target="link.target ? '_blank' : ''", :class="{'pointer-events-none': !link.url, 'router-link-active': isActive}", @click="onClick")
    slot
</template>

<script>
export default {
  name: 'FlexLink',
  props: {
    link: Object
  },
  computed: {
    linkUrl () {
      let url = this.link?.url
      if (url) {
        try {
          url = new URL(url)
        } catch (e) {
          // invalid url (relative or without http://)
          let host = url.split('/').length > 1 ? url.split('/')[0] : ''
          host = host.length ? host : window.location.host
          // simulate url object
          const pathname = url.slice(url.indexOf('/')) // "ria-craft.test/about" > "/about"
          url = { host, href: url, pathname }
        }

        // is relative?
        const api = new URL(process.env.VUE_APP_API) // http://ria-craft.test
        if (url.host.includes('therobertsinstituteofart.com') || url.host === api.host || url.host === window.location.host) {
          return url.pathname
        }
        // else full url
        return url.href
      }
      return url
    },
    isActive () {
      return this.$route.path === this.linkUrl
    }
  },
  methods: {
    onClick (e) {
      if (e.target.host === window.location.host) {
        e.preventDefault()
        if (this.$route.path !== e.target.pathname) {
          // full path including hash + query
          const fullPath = e.target.href.replace(e.target.origin, '')
          // router link
          this.$router.push(fullPath)
        }
      }
    }
  }
}

</script>

<style>
</style>
