import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/programme',
    redirect: '/'
  },
  // press
  {
    path: '/press',
    // name: '',
    component: () => import(/* webpackChunkName: "press" */ '../views/Press.vue'),
    children: [
      {
        path: '',
        name: 'press-index',
        component: () => import(/* webpackChunkName: "home" */ '../views/PressIndex.vue')
      },
      {
        path: ':entry',
        name: 'press-entry',
        component: () => import(/* webpackChunkName: "press" */ '../views/PressEntry.vue')
      }
    ]
  },
  {
    path: '/collection',
    component: () => import(/* webpackChunkName: "collection" */ '../views/Collection.vue'),
    children: [
      {
        path: 'loans',
        name: 'collection-loans',
        component: () => import(/* webpackChunkName: "collection-loans" */ '../views/CollectionLoans.vue')
      },
      {
        path: 'loans/:page',
        name: 'collection-loans-page',
        component: () => import(/* webpackChunkName: "collection-loan-request" */ '../views/CollectionLoansPage.vue')
      },
      {
        path: '',
        name: 'collection',
        component: () => import(/* webpackChunkName: "collection" */ '../views/CollectionIndex.vue'),
        meta: { group: 'index' },
        children: [
          {
            path: ':collectionItem',
            name: 'collection-item',
            component: () => import(/* webpackChunkName: "collection" */ '../views/CollectionItem.vue'),
            meta: { group: 'index', isOverlay: true }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '../views/Programme.vue'),
    children: [
      {
        path: '',
        name: 'programme-index',
        component: () => import(/* webpackChunkName: "home" */ '../views/ProgrammeIndex.vue'),
        meta: { panelOpen: true, filterBtn: true }
      },
      {
        path: '/programme/(now|next|past)',
        name: 'programme-dated',
        component: () => import(/* webpackChunkName: "programme" */ '../views/ProgrammeSection.vue'),
        meta: { filterBtn: true }
      },
      {
        path: '/programme/tagged/:tag',
        name: 'programme-tagged',
        component: () => import(/* webpackChunkName: "programme" */ '../views/ProgrammeSection.vue'),
        meta: { filterBtn: true }
      },
      {
        path: '/programme/:section',
        name: 'programme-section',
        component: () => import(/* webpackChunkName: "programme" */ '../views/ProgrammeSection.vue'),
        meta: { filterBtn: true }
      },
      {
        path: '/programme/:section/(now|next|past)',
        name: 'programme-section-dated',
        component: () => import(/* webpackChunkName: "programme" */ '../views/ProgrammeSection.vue'),
        meta: { filterBtn: true }
      },
      {
        path: '/programme/:section/tagged/:tag',
        name: 'programme-section-tagged',
        component: () => import(/* webpackChunkName: "programme" */ '../views/ProgrammeSection.vue'),
        meta: { filterBtn: true }
      },
      // entry
      {
        path: '/programme/:section/:entry',
        name: 'programme-section-entry',
        meta: { sidepanel: false },
        component: () => import(/* webpackChunkName: "programme-entry" */ '../views/ProgrammeEntry.vue')
      },
      // search
      {
        path: '/search/:term',
        name: 'programme-search',
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
      },
      // about
      {
        path: '/:page',
        name: 'programme-page',
        component: () => import(/* webpackChunkName: "about" */ '../views/Page.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // disable browser handling saved position
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'

    // !! exit if to overlay
    if (to.meta.isOverlay || from.meta.isOverlay) return false

    // wait for the route leave transition to end
    return new Promise(resolve => {
      if (to.hash) {
        this.app.$root.$once('routeEnter', () => {
          resolve({ selector: to.hash })
        })
      } else {
        this.app.$root.$once('afterRouteLeave', () => {
          const position = savedPosition || { x: 0, y: 0 }
          resolve(position)
        })
      }
    })
  }
})

export default router
