
<template lang="pug">
  form.newsletter-popup._call-to-action-row.bg-pink.text-18.md_text-20.xl_text-24.min-h-130.md_min-h-16vw.flex.items-center.justify-start.px-20.pb-30(
    @submit.prevent="subscribe"
    validate
    :class="{'hiddenPopup': !popUpVisible, 'visiblePopup': popUpVisible}"
  ).fixed.z-50.bottom-20.right-20.shadow-lg
    .absolute.top-0.right-0.pr-15.pt-5.text-24.text-black.cursor-pointer(
      @click="dismissPopup"
    ) ×
    //- wrapper
    .w-full.flex.flex-wrap.md_items-end.md_px-20
      //- heading column
      .w-full.md_w-auto.mb-8.md_mb-0
        //- heading
        h6.whitespace-no-wrap Stay up to date
        //- privacy policy text (hangs on lnd)
        .relative
          .absolute.top-40.md_top-auto.md_pt-18.text-9.md_text-10 View our #[router-link.shadow-underline(to="/privacy-policy") privacy policy]

      //- input column
      .w-full.flex-1.md_w-auto.flex.items-end
        //- input row
        .flex-1.md_w-1x2.pr-20.md_px-40
          .relative
            //- (status)
            .absolute.overlay.cursor-pointer(v-show="status", @click="reset(false)") {{ status }}
            //- (input)
            div(:class="{'invisible': status}")
              label.sr-only Email
              input.w-full.pt-10ff.border-b.border-current.-mb-px(ref="input", v-model="email", type="email", name="email", required, placeholder="your@email.com")

        //- sign up btn
        button.focus_bg-black-a10(type="submit")
          btn-boxed(size="smedium") Sign up
</template>

<script>
export default {
  name: 'NewsletterPopUp',
  data () {
    return {
      email: '',
      status: null, // 'Sending...'
      popUpVisible: false
    }
  },
  created () {
    const popupDelay = 9000
    const newsletterDismissed = localStorage.getItem('newsletterDismissed')
    if (!newsletterDismissed) {
      window.setTimeout(() => {
        this.popUpVisible = true
      }, popupDelay)
    }
  },
  methods: {
    dismissPopup () {
      this.popUpVisible = false
      localStorage.setItem('newsletterDismissed', true)
    },
    async subscribe (email) {
      this.status = 'Sending...'

      try {
        // submit...
        let resp = await fetch('/.netlify/functions/mailerlite-subscriber-add', {
          method: 'POST',
          body: JSON.stringify({ email: this.email })
        })
        resp = await resp.json()

        // ...API errors
        if (resp.error) {
          this.status = 'Error'
          return console.error(resp.error)
        }
        // ...success
        this.status = 'Thank you!'
        // reset
        setTimeout(() => this.reset(), 3000)
        // hide popup
        setTimeout(() => {
          this.popUpVisible = false
          localStorage.setItem('newsletterDismissed', true)
        }, 2000)
      } catch (e) {
        // connection error
        this.status = 'Error. Are you online?'
        console.error(e)
      }
    },

    reset (clear = true) {
      this.status = null
      if (clear) this.email = ''
      this.$nextTick(() => this.$refs.input.focus())
    }
  }
}
</script>

<style lang="css" scoped>
form {
  transition: transform 0.7s ease-in-out, opacity 1s ease-in-out;
}
.hiddenPopup {
  transform: translateX(120%);
  opacity: 0;
}
.visiblePopup {
  transform: translateX(0);
  opacity: 1;
}
input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}
</style>
