<template lang="pug">
  nav.app-footer-menu.flex.w-full.items-end.justify-between.md_block(role="navigation")
    ul.text-14.md_text-16.xl_text-18.flex-1.-my-5.md_my-0
      li.py-5.md_py-0(v-for="item in menu")
        flex-link.btn-theme-text(:link="item.flexLink") {{ item.flexLink.text }}
    //- social icons...
    ul.md_mt-30.xl_mt-40(:class="socialClasses")
      .flex.flex-wrap.-mx-6.xl_-mx-8
        li(v-for="item in socials.slice(0, 4)")
          flex-link.inline-block.px-6.xl_px-8(:link="item.flexLink")
            img.h-20.md_h-15.lg_h-20(:src="item.icon[0].url", :alt="item.icon[0].altText")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppFooterMenu',
  props: {
    socialClasses: { type: String, default: undefined }
  },
  computed: {
    ...mapState({
      menu: state => state.globals.footerMenu || [],
      socials: state => state.globals.socialMediaLinks || []
    })
  }
}
</script>

<style>
</style>
