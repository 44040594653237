import projectBody from './programme/projectBody'
import pressProjectBody from './press/projectBody'
import imageVideoClip from './common/imageVideoClip'

export const queryCollection =
`{
  entries (section: "collection", limit: 1000, orderBy: "artistSort,title") {
    title
    slug
    ... on collection_collection_Entry {
      artistSort
      artist
      year
      medium
      dimensions
      courtesy
      copyright
      photoCredit
      metaDescription
      indexImage: collectionIndexImage {
        url
        ... on images_Asset {
          width
          height
          altText
        }
      }
      slides: slidesCollectionEntry {
        ... on slidesCollectionEntry_slide_BlockType {
          id
          image {
            ... on images_Asset {
              title
              dateUpdated
              altText
              assetCaption
              courtesy
              copyright
              photoCredit
            }
            url
            width
            height
          }
          videoAsset {
            url
            ...on videos_Asset {
              videoPoster {
                url
              }
              assetCaption
              courtesy
              copyright
              photoCredit
            }
          }
          vimeoYoutubeEmbed
          captionType
          captionWidth
          captionColor
          captionCustom
          imageFit
          relatedContentLabel
          relatedContentFlexLink {
            customText
            target
            url
          }
        }
      }
    }
  }
}`

export const programmeEntryItemBody = `
  titleRich
  indexImage: programmeIndexImage {
    ${imageVideoClip()}
  }
  tags {
    title
    slug
  }
  dateStart
  dateEnd
  dateCustomText
`

export const programmeEntryItem = `
  ... on exhibitions_exhibitions_Entry {
    ${programmeEntryItemBody}
  }
  ... on performances_performances_Entry {
    ${programmeEntryItemBody}
  }
  ... on residencies_residencies_Entry {
    ${programmeEntryItemBody}
  }
  ... on projects_projects_Entry {
    ${programmeEntryItemBody}
  }
  ... on podcasts_podcasts_Entry {
    ${programmeEntryItemBody}
  }
`

export const queryProgramme =
`query ($section: [String]!, $relatedToTags: [TagCriteriaInput], $orderBy: String, $limit: Int, $offset: Int, $search: String, $dateStart: [QueryArgument], $dateEnd: [QueryArgument]) {
  entries (section: $section, relatedToTags: $relatedToTags, orderBy: $orderBy, limit: $limit,  offset: $offset, search: $search, dateStart: $dateStart, dateEnd: $dateEnd) {
    title
    slug
    section: sectionHandle
    ${programmeEntryItem}
  }
}`

// project

export const queryProgrammeEntry =
`query ProjectEntry ($slug: [String]) {
  entry (slug: $slug) {
    title
    postDate
    ... on exhibitions_exhibitions_Entry {
      ${projectBody()}
    }
    ... on performances_performances_Entry {
      intro
      ${projectBody('performance')}
    }
    ... on residencies_residencies_Entry {
      ${projectBody()}
    }
    ... on podcasts_podcasts_Entry {
      ${projectBody()}
    }
    ... on projects_projects_Entry {
      ${projectBody()}
    }
  }
}`

// --------- PRESS ---------
export const pressEntryItemBody = `
  titleRich
  externalLink
  indexImage: programmeIndexImage {
    ${imageVideoClip()}
  }
  tags {
    title
    slug
  }
  dateStart
  dateEnd
  dateCustomText
`

export const pressEntryItem = `
  ... on press_press_Entry {
    ${programmeEntryItemBody}
  }
`

export const queryPress =
`query ($section: [String]!, $relatedToTags: [TagCriteriaInput], $orderBy: String, $limit: Int, $offset: Int, $search: String, $dateStart: [QueryArgument], $dateEnd: [QueryArgument]) {
  entries (section: $section, relatedToTags: $relatedToTags, orderBy: $orderBy, limit: $limit,  offset: $offset, search: $search, dateStart: $dateStart, dateEnd: $dateEnd) {
    title
    slug
    section: sectionHandle
    ... on press_press_Entry {
      ${pressProjectBody()}
    }
  }
}`
// ${pressEntryItem}

export const queryPressEntry =
`query PressEntry ($slug: [String]) {
  entry (slug: $slug) {
    title
    postDate
    ... on press_press_Entry {
      ${pressProjectBody()}
    }
  }
}`

export const queryGlobals = `
query Globals {
  globalSets {
    ... on globals_GlobalSet {
      name
      footerMenu {
        ... on footerMenu_menuItem_BlockType {
          flexLink {
            text
            url
            target
          }
        }
      }
      socialMediaLinks {
        ... on socialMediaLinks_socialMediaIconLink1_BlockType {
          flexLink {
            text
            url
            target
          }
          icon {
            url
            ... on images_Asset {
              altText
            }
          }
        }
      }
      programmeMenu {
        ... on programmeMenu_menuItem_BlockType {
          flexLink {
            text
            url
          }
        }
      }
      tags {
        title
        slug
      }
      cookieBannerText
      metaDescription
      metaImage {
        url
      }
    }
  }
}
`

export const querySingle =
`query Single($section: [String]!) {
  entries (section: $section) {
    section: sectionHandle
    ...on collectionIndex_collectionIndex_Entry {
      metaDescription
      metaImage {
        url
      }
      intro
      loanRequestInfo
      loanGuidelines
    }
    ...on exhibitionsIndex_exhibitionsIndex_Entry {
      metaDescription
      metaImage {
        url
      }
      intro
    }
    ...on performancesIndex_performancesIndex_Entry {
      metaDescription
      metaImage {
        url
      }
      intro
      videoCarousel {
        ... on videoCarousel_videoAsset_BlockType {
          video {
            ... on videos_Asset {
              url
              videoPoster {
                ... on images_Asset {
                  url
                }
              }
            }
          }
        }
        ... on videoCarousel_vimeoYoutube_BlockType {
          vimeoYoutubeEmbed
        }
      }
    }
    ...on residenciesIndex_residenciesIndex_Entry {
      metaDescription
      metaImage {
        url
      }
      intro
    }
    ...on home_home_Entry {
      featuredProgramme {
        ... on featuredProgramme_largeEntry_BlockType {
          type: __typename
          entry {
            slug
            section: sectionHandle
            ${programmeEntryItem}
          }
          format
        }
        ... on featuredProgramme_smallEntries_BlockType {
          type: __typename
          entries {
            slug
            section: sectionHandle
            ${programmeEntryItem}
          }
          format
        }
        ... on featuredProgramme_callToAction_BlockType {
          type: __typename
          text
          flexLink {
            url
            target
          }
          boxLink {
            text
            url
            target
          }
        }
        ... on featuredProgramme_newsletterSignUp_BlockType {
          type: __typename
        }
        ... on featuredProgramme_callOutRow_BlockType {
          type: __typename
          text
        }
      }
    }
  }
}`

export const queryPage = `
query Page ($slug: [String]!) {
  entries (section: "pages", slug: $slug) {
    title
    slug
    ... on pages_pages_Entry {
      metaDescription
      metaImage {
        url
      }
      pagesBody {
        ... on pagesBody_typographicAbout_BlockType {
          type:__typename
          paragraph0
          paragraph1
          paragraph2
          paragraph3
        }
        ... on pagesBody_bodyText_BlockType {
          type: __typename
          text
        }
        ... on pagesBody_scrollPoint_BlockType {
          type:__typename
          heading
        }
        ... on pagesBody_callToAction_BlockType {
          type:__typename
          text
          flexLink {
            target
            url
          }
          boxLink {
            target
            text
            url
          }
        }
        ... on pagesBody_newsletterSignUp_BlockType {
          type:__typename
        }
      }
    }
  }
}
`
