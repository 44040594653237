<template lang="pug">
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <circle cx="9.07107" cy="9.07031" r="5" transform="rotate(-45 9.07107 9.07031)" stroke="black" stroke-width="1.5"/>
    <line x1="12.4297" y1="12.7822" x2="16.4297" y2="16.7822" stroke="black" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgEyeglass'
}
</script>

<style>
</style>
