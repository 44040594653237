<template lang="pug">
  observer.image-video-clip(:threshold="0.01", @visible="visible = true", @hidden="visible = false")
    //- video
    template(v-if="media && media.kind === 'video' && (!lazyVideo || loadVideo)")
      video.w-full.transition.duration-200(ref="video", :src="media.url", autoplay, loop, playsinline, muted, @loadedmetadata="videoLoaded = true", :class="[fit, {'absolute overlay': bg, 'opacity-0': !videoLoaded}]", :poster="videoPosterUrl")
      //- (mute btn)
      .absolute.bottom-0.left-0.p-10.text-white(v-if="muteBtn")
        //- plyr mute icon
        <button @click="muteToggle" type="button" :class="['plyr__control', {'plyr__control--pressed': muted}]" data-plyr="mute"><svg class="icon--pressed" aria-hidden="true" focusable="false"><use xlink:href="#plyr-muted"></use></svg><svg class="icon--not-pressed" aria-hidden="true" focusable="false"><use xlink:href="#plyr-volume"></use></svg><span class="label--pressed plyr__sr-only">Unmute</span><span class="label--not-pressed plyr__sr-only">Mute</span></button>

    //- image
    //- template(v-else-if="media && media.kind === 'image'")
    template(v-else-if="media")
      resp-img(v-bind="$attrs", :image="media", :bg="bg", :fit="fit")

</template>

<script>
export default {
  name: 'ImageVideoClip',
  props: {
    media: Object,
    bg: { type: Boolean, default: true },
    fit: { type: String, default: 'object-cover object-center' },
    lazyVideo: { type: Boolean, default: true },
    muteBtn: Boolean
  },
  data () {
    return {
      visible: false,
      loadVideo: false,
      videoLoaded: false,
      muted: true
    }
  },
  computed: {
    videoPosterUrl () {
      return this.media.videoPoster && this.media.videoPoster[0]?.url
    }
  },
  methods: {
    playVideo () {
      return this.$refs.video?.paused && this.$refs.video.play()
    },
    pauseVideo () {
      return this.$refs.video && !this.$refs.video.paused && this.$refs.video.pause()
    },
    muteToggle () {
      if (this.$refs.video) {
        this.muted = this.$refs.video.muted = !this.$refs.video.muted
      }
    }
  },
  watch: {
    visible (vis) {
      if (vis) {
        this.loadVideo = true
        this.playVideo()
      } else {
        this.pauseVideo()
      }
    }
  }
}
</script>

<style>
</style>
