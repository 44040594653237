import imageVideoClip from '@/graphql/common/imageVideoClip'

export default `
  projectBlocks {
    ... on projectBlocks_textAndImageSquare_BlockType {
      type:__typename
      text
      image {
        ${imageVideoClip()}
      }
      layout
    }
    ... on projectBlocks_accentImage_BlockType {
      type:__typename
      image {
        ${imageVideoClip()}
      }
      align
    }
    ... on projectBlocks_bodyText_BlockType {
      type: __typename
      details
      text
    }
    ... on projectBlocks_image_BlockType {
      type: __typename
      image {
        ${imageVideoClip(true)}
      }
    }
    ... on projectBlocks_imageGrid_BlockType {
      type: __typename
      aspectRatio
      images {
        ${imageVideoClip(true)}
      }
    }
    ... on projectBlocks_carousel_BlockType {
      type: __typename
      heading
      slides {
        ... on slides_BlockType {
          image {
            ${imageVideoClip(true)}
          }
          captionCustom
        }
      }
    }
    ... on projectBlocks_callToAction_BlockType {
      type: __typename
      text
      flexLink {
        url
        target
      }
      boxLink {
        text
        url
        target
      }
    }
    ... on projectBlocks_boardList_BlockType {
      type: __typename
      list {
        name
      }
    }
    ... on projectBlocks_audioPlayer_BlockType {
      type: __typename
      label
      heading
      audioEntry {
        ... on podcasts_podcasts_Entry {
          audioSourceLink {
            url
          }
        }
      }
      audioCustomSrc {
        url
      }
      boxLinks {
        ... on boxLinks_BlockType {
          flexLink {
            customText
            target
            url
          }
        }
      }
    }
    ... on projectBlocks_vimeoYoutubePlayer_BlockType {
      type: __typename
      vimeoYoutubeEmbed
    }
    ... on projectBlocks_videoPlayer_BlockType {
      type: __typename
      videoAsset {
        ... on videos_Asset {
          url
          videoPoster {
            ... on images_Asset {
              url
            }
          }
        }  
      }
    }
    ... on projectBlocks_scriptToggle_BlockType {
      type: __typename
      label
      script {
        ... on files_Asset {
          url
        }
      }
      scriptDestroyMethod
    }
    ... on projectBlocks_embed_BlockType {
      type: __typename
      embedCode
    }
    ... on projectBlocks_imageVideo_BlockType {
      type:__typename
      image {
        ${imageVideoClip()}
      }
    }
  }
`
