<template lang="pug">
  .container-panel-active.md_px-20.flex.w-full.justify-end
    .w-full.md_w-10x12.md_px-20
      .w-full.md_w-full_80.md_-ml-40
        slot
</template>

<script>
export default {
  name: 'ContainerPanelActive'
}
</script>

<style>
</style>
