<template>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 429.2 62.7" style="enable-background:new 0 0 429.2 62.7;" xml:space="preserve">
<g>
    <polygon points="54.7,28.9 70.4,28.9 70.4,26.4 57.5,26.4 57.5,19.3 70.1,19.3 70.1,16.8 57.5,16.8 57.5,9.8 70.4,9.8 70.4,7.3
        54.7,7.3    "/>
    <polygon points="43.6,7.3 43.6,16.8 28.6,16.8 28.6,7.3 25.8,7.3 25.8,28.9 28.6,28.9 28.6,19.3 43.6,19.3 43.6,28.9 46.4,28.9
        46.4,7.3    "/>
    <polygon points="20.3,7.3 0,7.3 0,9.8 8.8,9.8 8.8,28.9 11.5,28.9 11.5,9.8 20.3,9.8  "/>
</g>
<path d="M87.4,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6H87.4V7.3z
     M98,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L98,26.4L98,26.4z"/>
<path d="M129.5,21.2h-10.6l-3.5,7.7h-3.1l9.8-21.6h4.1l9.8,21.6h-3L129.5,21.2z M128.4,18.7l-4-9.1h-0.2l-4,9.1H128.4z"/>
<path d="M163.9,7.3h2.8v21.6h-2.8V7.3z"/>
<path d="M174.7,7.3H185c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6h-10.3V7.3z
     M185.3,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L185.3,26.4
    L185.3,26.4z"/>
<path d="M227.6,21.2H217l-3.5,7.7h-3.1l9.8-21.6h4.1l9.8,21.6H231L227.6,21.2z M226.4,18.7l-4-9.1h-0.2l-4,9.1H226.4z"/>
<path d="M239.6,7.3h4.3l13.5,18.5V7.3h2.8v21.6h-4.3l-13.5-18.5v18.5h-2.8V7.3z"/>
<path d="M267.5,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6h-10.3V7.3z
     M278.1,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L278.1,26.4
    L278.1,26.4z"/>
<path d="M306.3,7.3h2.8v21.6h-2.8V7.3z"/>
<path d="M317.1,7.3h4.3l13.5,18.5V7.3h2.8v21.6h-4.3l-13.5-18.5v18.5h-2.8V7.3z"/>
<path d="M345,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6H345V7.3z
     M355.6,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L355.6,26.4
    L355.6,26.4z"/>
<path d="M387.2,28.9l-7.9-9.7H376v9.7h-2.8V7.3h10.6c3.1,0,5.2,0.9,6.3,2.8c0.5,0.9,0.7,1.9,0.7,3.1c0,1.1-0.2,2.2-0.7,3
    c-1.1,1.9-3.5,2.7-6.6,2.7h-1l8.3,9.9L387.2,28.9L387.2,28.9z M376,16.7h7.9c1.8,0,3.1-0.5,3.7-1.6c0.3-0.5,0.5-1.2,0.5-1.9
    s-0.1-1.3-0.4-1.8c-0.6-1.1-1.9-1.7-3.7-1.7h-8V16.7z"/>
<path d="M397.4,7.3h15.7v2.5h-12.9v6.9h12.6v2.5h-12.6v7.1h12.9v2.5h-15.7V7.3z"/>
<path d="M403.7,0h3.1v3.6h-3.1V0z"/>
<polygon points="145.2,28.9 149.5,28.9 158.7,7.3 155.5,7.3 147.4,26.8 147.2,26.8 139.1,7.3 136,7.3 "/>
<g>
    <path d="M188.2,55.5c-0.5-1.3-0.7-2.7-0.7-4.2s0.2-2.9,0.7-4.2c1.7-4.5,6.4-7.2,12.3-7.2c6.8,0,11.4,3.5,11.8,8.3h-2.9
        c-0.6-3.4-3.9-5.8-8.9-5.8c-4.7,0-8.3,2-9.6,5.6c-0.4,1-0.6,2.1-0.6,3.3s0.2,2.3,0.6,3.3c1.3,3.5,4.9,5.6,9.5,5.6
        c4.9,0,7.9-2.3,8.7-5.8h2.9c-0.7,4.8-4.9,8.3-11.7,8.3C194.5,62.7,189.8,60.1,188.2,55.5z"/>
    <path d="M218,55.5c-0.5-1.3-0.7-2.7-0.7-4.1c0-1.4,0.2-2.8,0.7-4.1c1.6-4.5,6.3-7.3,12.5-7.3c6.3,0,10.9,2.8,12.5,7.3
        c0.5,1.3,0.7,2.7,0.7,4.1c0,1.4-0.2,2.8-0.7,4.1c-1.6,4.4-6.2,7.3-12.5,7.3C224.3,62.7,219.6,60,218,55.5z M240.3,54.5
        c0.4-1,0.6-2.1,0.6-3.2s-0.2-2.2-0.6-3.2c-1.2-3.5-4.8-5.6-9.8-5.6c-4.9,0-8.5,2.2-9.8,5.6c-0.4,1-0.6,2.1-0.6,3.2s0.2,2.2,0.6,3.2
        c1.3,3.5,4.8,5.6,9.8,5.6C235.4,60.2,239,58.1,240.3,54.5z"/>
    <path d="M250.1,40.5h2.8v19.1h12.8v2.5h-15.6V40.5z"/>
    <path d="M271.3,40.5h2.8v19.1h12.8v2.5h-15.6L271.3,40.5L271.3,40.5z"/>
    <path d="M292.5,40.5h15.7V43h-12.9v7h12.6v2.5h-12.6v7.1h12.9v2.5h-15.7V40.5z"/>
    <path d="M314.3,55.5c-0.5-1.3-0.7-2.7-0.7-4.2s0.2-2.9,0.7-4.2c1.7-4.5,6.4-7.2,12.3-7.2c6.8,0,11.4,3.5,11.8,8.3h-2.9
        c-0.6-3.4-3.9-5.8-8.9-5.8c-4.7,0-8.3,2-9.6,5.6c-0.4,1-0.6,2.1-0.6,3.3s0.2,2.3,0.6,3.3c1.3,3.5,4.9,5.6,9.5,5.6
        c4.9,0,7.9-2.3,8.7-5.8h2.9c-0.7,4.8-4.9,8.3-11.7,8.3C320.7,62.7,316,60.1,314.3,55.5z"/>
    <path d="M362.1,43.1h-8.8v19.1h-2.8V43.1h-8.8v-2.5H362L362.1,43.1L362.1,43.1z"/>
    <path d="M367.2,40.5h2.8v21.6h-2.8V40.5z"/>
    <path d="M377.1,55.5c-0.5-1.3-0.7-2.7-0.7-4.1c0-1.4,0.2-2.8,0.7-4.1c1.6-4.5,6.3-7.3,12.5-7.3c6.3,0,10.9,2.8,12.5,7.3
        c0.5,1.3,0.7,2.7,0.7,4.1c0,1.4-0.2,2.8-0.7,4.1c-1.6,4.4-6.2,7.3-12.5,7.3C383.5,62.7,378.8,60,377.1,55.5z M399.4,54.5
        c0.4-1,0.6-2.1,0.6-3.2s-0.2-2.2-0.6-3.2c-1.2-3.5-4.8-5.6-9.8-5.6c-4.9,0-8.5,2.2-9.8,5.6c-0.4,1-0.6,2.1-0.6,3.2s0.2,2.2,0.6,3.2
        c1.3,3.5,4.8,5.6,9.8,5.6C394.6,60.2,398.2,58.1,399.4,54.5z"/>
    <path d="M408.7,40.5h4.3L426.5,59V40.5h2.8v21.6H425l-13.5-18.5v18.5h-2.8V40.5z"/>
    <g>
        <path d="M162.4,62.7c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3s-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
            c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5s0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9v-0.4
            c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
            c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5s-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5V55h-2.9v0.4
            C152.9,60.1,156.7,62.7,162.4,62.7z"/>
        <polygon points="149,40.5 128.6,40.5 128.6,43.1 137.4,43.1 137.4,62.1 140.2,62.1 140.2,43.1 149,43.1        "/>
        <path d="M109.7,43.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8s-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9
            L109.7,43.1L109.7,43.1z M124.6,62.1l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2
            c-1.1-1.8-3.2-2.8-6.3-2.8H107v21.6h2.8v-9.7h3.3l7.9,9.7H124.6z"/>
        <polygon points="83.9,62.1 99.6,62.1 99.6,59.6 86.7,59.6 86.7,52.5 99.3,52.5 99.3,50 86.7,50 86.7,43.1 99.6,43.1 99.6,40.5
            83.9,40.5       "/>
        <path d="M73.3,57.9c-0.7,1.1-2,1.7-4,1.7h-7.7v-7.1h7.7c1.9,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.8
            C73.7,56.8,73.6,57.4,73.3,57.9z M61.6,43.1h7.7c2,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.9c0,0.7-0.2,1.3-0.5,1.8
            c-0.6,1.1-2,1.7-4,1.7h-7.7C61.6,50.2,61.6,43.1,61.6,43.1z M71.8,51.4L71.8,51.4c1.9-0.3,3.4-1.2,4.1-2.6
            c0.4-0.7,0.6-1.5,0.6-2.5c0-1.1-0.2-2-0.7-2.8c-1-1.8-3.2-2.9-6.4-2.9H58.8v21.6h10.7c3.2,0,5.3-1.1,6.4-2.9
            c0.5-0.8,0.7-1.7,0.7-2.8c0-1-0.2-1.8-0.6-2.5C75.2,52.5,73.7,51.6,71.8,51.4"/>
        <path d="M38.8,60.2c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2c0-1.1,0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6
            s8.5,2.2,9.8,5.6c0.4,1,0.6,2.1,0.6,3.2c0,1.1-0.2,2.2-0.6,3.2C47.3,58.1,43.7,60.2,38.8,60.2 M38.9,62.7c6.3,0,10.9-2.8,12.5-7.2
            c0.5-1.3,0.7-2.7,0.7-4.1c0-1.4-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2c-0.5,1.3-0.7,2.7-0.7,4.1
            c0,1.4,0.2,2.8,0.7,4.1C28,60,32.6,62.7,38.9,62.7"/>
        <path d="M5.8,43.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8s-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6H5.8V43.1z
             M20.7,62.1l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8H3v21.6h2.8
            v-9.7h3.3l7.9,9.7H20.7z"/>
    </g>
</g>
</svg>

</template>

<script>
export default {
  name: 'SvgLogoCollectionMobile'
}
</script>

<style>
</style>
