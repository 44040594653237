<template>
  <svg viewBox="0 0 28 40" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M21.12 40L28 40L5.76 20L28 0L21.12 -6.01469e-07L1.74846e-06 20L21.12 40Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgChevronLeft'
}
</script>

<style>
</style>
