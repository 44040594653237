<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 342.4 56" style="enable-background:new 0 0 342.4 56;" xml:space="preserve">
  <g>
    <g>
      <g>
        <polygon points="342.4,33.8 322.1,33.8 322.1,36.3 330.9,36.3 330.9,55.4 333.6,55.4 333.6,36.3 342.4,36.3      "/>
        <path d="M303.2,36.3h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9
          V36.3z M318.1,55.4l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8
          h-10.6v21.6h2.8v-9.7h3.3l7.9,9.7H318.1z"/>
        <path d="M278.6,45.2l4-9.1h0.2l4,9.1H278.6z M291.5,55.4h3.1l-9.8-21.6h-4.1l-9.8,21.6h3.1l3.5-7.7H288L291.5,55.4z"/>
        <polygon points="241,55.4 243.8,55.4 243.8,45.8 256.2,45.8 256.2,43.3 243.8,43.3 243.8,36.3 256.7,36.3 256.7,33.8 241,33.8
                "/>
        <path d="M221.1,53.5c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2c0-1.1,0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6
          s8.5,2.2,9.8,5.6c0.4,1,0.6,2.1,0.6,3.2c0,1.1-0.2,2.2-0.6,3.2C229.6,51.4,225.9,53.5,221.1,53.5 M221.1,56
          c6.3,0,10.9-2.8,12.5-7.2c0.5-1.3,0.7-2.7,0.7-4.1c0-1.4-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2
          c-0.5,1.3-0.7,2.7-0.7,4.1c0,1.4,0.2,2.8,0.7,4.1C210.2,53.3,214.9,56,221.1,56"/>
      </g>
      <g>
        <polygon points="175.4,55.4 191.2,55.4 191.2,52.9 178.2,52.9 178.2,45.8 190.9,45.8 190.9,43.3 178.2,43.3 178.2,36.3
          191.2,36.3 191.2,33.8 175.4,33.8      "/>
        <polygon points="169.9,33.8 149.6,33.8 149.6,36.3 158.4,36.3 158.4,55.4 161.1,55.4 161.1,36.3 169.9,36.3      "/>
        <path d="M134.3,56c6.2,0,10-3.5,10-9.3V33.8h-2.9v12.9c0,4.2-2.7,6.7-7.2,6.7c-4.4,0-7.1-2.6-7.1-6.7V33.8h-2.9v12.9
          C124.3,52.5,128.1,56,134.3,56"/>
        <polygon points="119.1,33.8 98.8,33.8 98.8,36.3 107.6,36.3 107.6,55.4 110.3,55.4 110.3,36.3 119.1,36.3      "/>
        <rect x="90.5" y="33.8" width="2.8" height="21.6"/>
        <polygon points="85,33.8 64.6,33.8 64.6,36.3 73.4,36.3 73.4,55.4 76.2,55.4 76.2,36.3 85,36.3      "/>
        <path d="M50.9,56c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3c0-1.1-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
          c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5c0-0.6,0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9
          v-0.4c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
          c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5c0,0.6-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5v-0.2h-2.9
          v0.4C41.4,53.4,45.2,56,50.9,56z"/>
        <polygon points="13.7,55.4 16.5,55.4 16.5,36.9 30,55.4 34.3,55.4 34.3,33.8 31.5,33.8 31.5,52.3 18,33.8 13.7,33.8      "/>
        <rect x="2.7" y="33.8" width="2.8" height="21.6"/>
      </g>
    </g>
    <g>
      <path d="M248.1,22.8c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3c0-1.1-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
        c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5c0-0.6,0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9
        V7.2c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
        c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5c0,0.6-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5v-0.2h-2.9
        v0.4C238.6,20.2,242.4,22.8,248.1,22.8z"/>
      <polygon points="234.6,0.6 214.3,0.6 214.3,3.1 223.1,3.1 223.1,22.2 225.8,22.2 225.8,3.1 234.6,3.1    "/>
      <path d="M195.4,3.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9V3.1z
         M210.3,22.2l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8h-10.6v21.6
        h2.8v-9.7h3.3l7.9,9.7H210.3z"/>
      <polygon points="169.5,22.2 185.3,22.2 185.3,19.7 172.3,19.7 172.3,12.6 185,12.6 185,10.1 172.3,10.1 172.3,3.1 185.3,3.1
        185.3,0.6 169.5,0.6     "/>
      <path d="M158.9,18c-0.7,1.1-2,1.7-4,1.7h-7.7v-7.1h7.7c1.9,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.8
        C159.4,16.8,159.3,17.5,158.9,18z M147.2,3.1h7.7c2,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.9c0,0.7-0.2,1.3-0.5,1.8
        c-0.6,1.1-2,1.7-4,1.7h-7.7V3.1z M157.5,11.4L157.5,11.4c1.9-0.3,3.4-1.2,4.1-2.6c0.4-0.7,0.6-1.5,0.6-2.5c0-1.1-0.2-2-0.7-2.8
        c-1-1.8-3.2-2.9-6.4-2.9h-10.7v21.6h10.7c3.2,0,5.3-1.1,6.4-2.9c0.5-0.8,0.7-1.7,0.7-2.8c0-1-0.2-1.8-0.6-2.5
        C160.8,12.6,159.4,11.6,157.5,11.4"/>
      <path d="M124.5,20.2c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2c0-1.1,0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6
        s8.5,2.2,9.8,5.6c0.4,1,0.6,2.1,0.6,3.2c0,1.1-0.2,2.2-0.6,3.2C133,18.1,129.4,20.2,124.5,20.2 M124.5,22.8
        c6.3,0,10.9-2.8,12.5-7.2c0.5-1.3,0.7-2.7,0.7-4.1c0-1.4-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2
        c-0.5,1.3-0.7,2.7-0.7,4.1c0,1.4,0.2,2.8,0.7,4.1C113.6,20.1,118.3,22.8,124.5,22.8"/>
      <path d="M91.5,3.1h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8c0,0.7-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9V3.1z
         M106.4,22.2l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8H88.7v21.6
        h2.8v-9.7h3.3l7.9,9.7H106.4z"/>
    </g>
    <g>
      <polygon points="54.7,22.2 70.4,22.2 70.4,19.7 57.5,19.7 57.5,12.6 70.1,12.6 70.1,10.1 57.5,10.1 57.5,3.1 70.4,3.1 70.4,0.6
        54.7,0.6    "/>
      <polygon points="43.6,0.6 43.6,10.1 28.6,10.1 28.6,0.6 25.8,0.6 25.8,22.2 28.6,22.2 28.6,12.6 43.6,12.6 43.6,22.2 46.4,22.2
        46.4,0.6    "/>
      <polygon points="20.3,0.6 0,0.6 0,3.1 8.8,3.1 8.8,22.2 11.5,22.2 11.5,3.1 20.3,3.1    "/>
    </g>
  </g>
  </svg>

</template>

<script>
export default {
  name: 'SvgLogoRIATwoLines'
}
</script>

<style>
</style>
