import projectBlocks from './projectBlocks'
import performanceBlocks from './performanceBlocks'
import { pressEntryItem } from '@/graphql/queries'
import imageVideoClip from '@/graphql/common/imageVideoClip'

const blocks = {
  project: projectBlocks,
  performance: performanceBlocks
}

// "Cannot query field "programmeIndexImage" on type "press_press_Entry"."

export default (type = 'project') => `
  titleRich
  externalLink
  headingColor
  tags {
    title
    slug
  }
  dateStart
  dateEnd
  dateCustomText
  metaDescription
  metaImage {
    url
  }
  indexImage: programmeIndexImage {
    url
  }
  projectLanding {
    ... on projectLanding_heroImage_BlockType {
      type: __typename
      image {
        ${imageVideoClip()}
      }
    }
    ... on projectLanding_vimeoYoutubePlayer_BlockType {
      type: __typename
      vimeoYoutubeEmbed
    }
    ... on projectLanding_videoPlayer_BlockType {
      type: __typename
      videoAsset {
        ... on videos_Asset {
          url
          width
          height
          videoPoster {
            ... on images_Asset {
              url
            }
          }
        }  
      }
    }
  }
  body: ${blocks[type] || ''}
  footerTopics {
    ... on footerTopics_topic_BlockType {
      type: __typename
      topics {
        ... on topics_BlockType {
          heading
          text
          image {
            url
            width
            height
            ... on images_Asset {
              altText
            }
          }
          footerLinks {
            ... on footerLinks_boxLink_BlockType {
              type: __typename
              flexLink {
                text
                url
                target
              }
            }
            ... on footerLinks_linkWithText_BlockType {
              type: __typename
              flexLink {
                text
                url
                target
              }
              text
            }
          }
        }
      }
    }
  }
  relatedPosts {
    slug
    section: sectionHandle
  
    # programme entry
    ${pressEntryItem}
  
    # collection entry
    ... on collection_collection_Entry {
      artist
      title
      indexImage: collectionIndexImage {
        url
        kind
        ...on images_Asset{
          width
          height
          altText
        }
      }
      firstSlide: slidesCollectionEntry (limit:1) {
        ... on slidesCollectionEntry_slide_BlockType {
          image {
            url
            kind
            ... on images_Asset {
              width
              height
              altText
            }
          }
        }
      }
    }
  }
`
