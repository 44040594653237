<template lang="pug">
  figcaption.image-hover-caption.md_absolute.md_-bottom-15.left-0.md_bg-white.text-black.w-full.pt-5.md_py-15.pr-15.md_opacity-0.group-hover_opacity-100.transition.duration-150.text-10.xl_text-12
    image-asset-caption(:image="image", :caption="true")
    //- div(v-html="image.assetCaption")
    //- small.block(v-if="credits") {{ credits }}
</template>

<script>
export default {
  name: 'ImageHoverCaption',
  props: ['image']
  // computed: {
  //   credits () {
  //     const keys = ['courtesy', 'copyright', 'photoCredit']
  //     const credit = []
  //     keys.forEach(key => this.image[key] && credit.push(this.image[key]))
  //     return credit.length && credit.join(', ')
  //   }
  // }
}
</script>

<style>
</style>
