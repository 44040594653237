<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 858 29.5" style="enable-background:new 0 0 858 29.5;" xml:space="preserve">
<g>
  <polygon points="54.7,28.9 70.4,28.9 70.4,26.4 57.5,26.4 57.5,19.3 70.1,19.3 70.1,16.8 57.5,16.8 57.5,9.8 70.4,9.8 70.4,7.3
    54.7,7.3  "/>
  <polygon points="43.6,7.3 43.6,16.8 28.6,16.8 28.6,7.3 25.8,7.3 25.8,28.9 28.6,28.9 28.6,19.3 43.6,19.3 43.6,28.9 46.4,28.9
    46.4,7.3  "/>
  <polygon points="20.3,7.3 0,7.3 0,9.8 8.8,9.8 8.8,28.9 11.5,28.9 11.5,9.8 20.3,9.8  "/>
</g>
<path d="M87.4,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6H87.4V7.3z
   M98,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L98,26.4L98,26.4z"/>
<path d="M129.5,21.2h-10.6l-3.5,7.7h-3.1l9.8-21.6h4.1l9.8,21.6h-3L129.5,21.2z M128.4,18.7l-4-9.1h-0.2l-4,9.1H128.4z"/>
<path d="M163.9,7.3h2.8v21.6h-2.8V7.3z"/>
<path d="M174.7,7.3H185c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6h-10.3V7.3z
   M185.3,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L185.3,26.4
  L185.3,26.4z"/>
<path d="M227.6,21.2H217l-3.5,7.7h-3.1l9.8-21.6h4.1l9.8,21.6H231L227.6,21.2z M226.4,18.7l-4-9.1h-0.2l-4,9.1H226.4z"/>
<path d="M239.6,7.3h4.3l13.5,18.5V7.3h2.8v21.6h-4.3l-13.5-18.5v18.5h-2.8V7.3z"/>
<path d="M267.5,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6h-10.3V7.3z
   M278.1,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L278.1,26.4
  L278.1,26.4z"/>
<path d="M306.3,7.3h2.8v21.6h-2.8V7.3z"/>
<path d="M317.1,7.3h4.3l13.5,18.5V7.3h2.8v21.6h-4.3l-13.5-18.5v18.5h-2.8V7.3z"/>
<path d="M345,7.3h10.3c5.4,0,9.3,2.4,10.8,6.6c0.4,1.3,0.7,2.7,0.7,4.2c0,1.6-0.2,3-0.7,4.2c-1.5,4.1-5.3,6.6-10.8,6.6H345V7.3z
   M355.6,26.4c3.9,0,6.6-1.9,7.7-5c0.3-1,0.5-2.1,0.5-3.3c0-1.2-0.2-2.3-0.6-3.4c-1.1-3-3.8-4.9-7.7-4.9h-7.8v16.5L355.6,26.4
  L355.6,26.4z"/>
<path d="M387.2,28.9l-7.9-9.7H376v9.7h-2.8V7.3h10.6c3.1,0,5.2,0.9,6.3,2.8c0.5,0.9,0.7,1.9,0.7,3.1c0,1.1-0.2,2.2-0.7,3
  c-1.1,1.9-3.5,2.7-6.6,2.7h-1l8.3,9.9L387.2,28.9L387.2,28.9z M376,16.7h7.9c1.8,0,3.1-0.5,3.7-1.6c0.3-0.5,0.5-1.2,0.5-1.9
  s-0.1-1.3-0.4-1.8c-0.6-1.1-1.9-1.7-3.7-1.7h-8V16.7z"/>
<path d="M397.4,7.3h15.7v2.5h-12.9v6.9h12.6v2.5h-12.6v7.1h12.9v2.5h-15.7V7.3z"/>
<path d="M403.7,0h3.1v3.6h-3.1V0z"/>
<polygon points="145.2,28.9 149.5,28.9 158.7,7.3 155.5,7.3 147.4,26.8 147.2,26.8 139.1,7.3 136,7.3 "/>
<g>
  <path d="M617,22.3c-0.5-1.3-0.7-2.7-0.7-4.2s0.2-2.9,0.7-4.2c1.7-4.5,6.4-7.2,12.3-7.2c6.8,0,11.4,3.5,11.8,8.3h-2.9
    c-0.6-3.4-3.9-5.8-8.9-5.8c-4.7,0-8.3,2-9.6,5.6c-0.4,1-0.6,2.1-0.6,3.3c0,1.2,0.2,2.3,0.6,3.3c1.3,3.5,4.9,5.6,9.5,5.6
    c4.9,0,7.9-2.3,8.7-5.8h2.9c-0.7,4.8-4.9,8.3-11.7,8.3C623.3,29.5,618.6,26.8,617,22.3z"/>
  <path d="M646.8,22.2c-0.5-1.3-0.7-2.7-0.7-4.1s0.2-2.8,0.7-4.1c1.6-4.5,6.3-7.3,12.5-7.3c6.3,0,10.9,2.8,12.5,7.3
    c0.5,1.3,0.7,2.7,0.7,4.1s-0.2,2.8-0.7,4.1c-1.6,4.4-6.2,7.3-12.5,7.3C653.1,29.5,648.4,26.8,646.8,22.2z M669.1,21.3
    c0.4-1,0.6-2.1,0.6-3.2s-0.2-2.2-0.6-3.2c-1.2-3.5-4.8-5.6-9.8-5.6c-4.9,0-8.5,2.2-9.8,5.6c-0.4,1-0.6,2.1-0.6,3.2s0.2,2.2,0.6,3.2
    c1.3,3.5,4.8,5.6,9.8,5.6C664.2,27,667.8,24.9,669.1,21.3z"/>
  <path d="M678.9,7.3h2.8v19.1h12.8v2.5h-15.6V7.3z"/>
  <path d="M700.1,7.3h2.8v19.1h12.8v2.5h-15.6V7.3z"/>
  <path d="M721.3,7.3H737v2.5h-12.9v6.9h12.6v2.5h-12.6v7.1H737v2.5h-15.7V7.3z"/>
  <path d="M743.1,22.3c-0.5-1.3-0.7-2.7-0.7-4.2s0.2-2.9,0.7-4.2c1.7-4.5,6.4-7.2,12.3-7.2c6.8,0,11.4,3.5,11.8,8.3h-2.9
    c-0.6-3.4-3.9-5.8-8.9-5.8c-4.7,0-8.3,2-9.6,5.6c-0.4,1-0.6,2.1-0.6,3.3c0,1.2,0.2,2.3,0.6,3.3c1.3,3.5,4.9,5.6,9.5,5.6
    c4.9,0,7.9-2.3,8.7-5.8h2.9c-0.7,4.8-4.9,8.3-11.7,8.3C749.5,29.5,744.8,26.8,743.1,22.3z"/>
  <path d="M790.9,9.8h-8.8v19.1h-2.8V9.8h-8.8V7.3h20.3L790.9,9.8L790.9,9.8z"/>
  <path d="M796,7.3h2.8v21.6H796V7.3z"/>
  <path d="M805.9,22.2c-0.5-1.3-0.7-2.7-0.7-4.1s0.2-2.8,0.7-4.1c1.6-4.5,6.3-7.3,12.5-7.3c6.3,0,10.9,2.8,12.5,7.3
    c0.5,1.3,0.7,2.7,0.7,4.1s-0.2,2.8-0.7,4.1c-1.6,4.4-6.2,7.3-12.5,7.3C812.3,29.5,807.6,26.8,805.9,22.2z M828.2,21.3
    c0.4-1,0.6-2.1,0.6-3.2s-0.2-2.2-0.6-3.2c-1.2-3.5-4.8-5.6-9.8-5.6c-4.9,0-8.5,2.2-9.8,5.6c-0.4,1-0.6,2.1-0.6,3.2s0.2,2.2,0.6,3.2
    c1.3,3.5,4.8,5.6,9.8,5.6C823.3,27,827,24.9,828.2,21.3z"/>
  <path d="M837.5,7.3h4.3l13.5,18.5V7.3h2.8v21.6h-4.3l-13.5-18.5v18.5h-2.8V7.3z"/>
  <g>
    <path d="M591.2,29.5c4.2,0,7.3-1.3,8.6-3.6c0.5-0.9,0.7-1.9,0.7-3s-0.2-2-0.6-2.8c-0.9-1.6-2.8-2.6-5.9-3l-4.5-0.5
      c-2.4-0.3-3.6-1-4.2-1.9c-0.2-0.5-0.4-0.9-0.4-1.5s0.2-1.2,0.4-1.7c0.8-1.4,2.9-2.2,5.9-2.2c4,0,6.5,1.6,6.5,4.8v0.3h2.9V14
      c0-4.7-3.7-7.2-9.3-7.2c-4.2,0-7.3,1.4-8.6,3.6c-0.5,0.9-0.8,1.9-0.8,3c0,1,0.2,2,0.7,2.7c0.9,1.6,2.8,2.7,5.9,3l4.5,0.5
      c2.2,0.3,3.6,0.9,4.1,1.9c0.2,0.4,0.3,0.9,0.3,1.5s-0.2,1.2-0.4,1.7c-0.8,1.4-2.9,2.3-5.9,2.3c-4,0-6.6-1.7-6.6-5v-0.2h-2.9v0.4
      C581.7,26.9,585.5,29.5,591.2,29.5z"/>
    <polygon points="577.7,7.3 557.4,7.3 557.4,9.8 566.2,9.8 566.2,28.9 569,28.9 569,9.8 577.7,9.8    "/>
    <path d="M538.5,9.8h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8s-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9L538.5,9.8
      L538.5,9.8z M553.4,28.9l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8
      h-10.6v21.6h2.8v-9.7h3.3l7.9,9.7C549.8,28.9,553.4,28.9,553.4,28.9z"/>
    <polygon points="512.7,28.9 528.4,28.9 528.4,26.4 515.5,26.4 515.5,19.3 528.1,19.3 528.1,16.8 515.5,16.8 515.5,9.8 528.4,9.8
      528.4,7.3 512.7,7.3     "/>
    <path d="M502.1,24.7c-0.7,1.1-2,1.7-4,1.7h-7.7v-7.1h7.7c1.9,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.8
      C502.5,23.6,502.4,24.2,502.1,24.7z M490.4,9.8h7.7c2,0,3.3,0.6,4,1.7c0.3,0.5,0.5,1.1,0.5,1.9c0,0.7-0.2,1.3-0.5,1.8
      c-0.6,1.1-2,1.7-4,1.7h-7.7V9.8z M500.6,18.1L500.6,18.1c1.9-0.3,3.4-1.2,4.1-2.6c0.4-0.7,0.6-1.5,0.6-2.5c0-1.1-0.2-2-0.7-2.8
      c-1-1.8-3.2-2.9-6.4-2.9h-10.7v21.6h10.7c3.2,0,5.3-1.1,6.4-2.9c0.5-0.8,0.7-1.7,0.7-2.8c0-1-0.2-1.8-0.6-2.5
      C504,19.3,502.5,18.3,500.6,18.1"/>
    <path d="M467.6,27c-4.9,0-8.5-2.2-9.8-5.6c-0.4-1-0.6-2.1-0.6-3.2s0.2-2.2,0.6-3.2c1.2-3.5,4.8-5.6,9.8-5.6s8.5,2.2,9.8,5.6
      c0.4,1,0.6,2.1,0.6,3.2s-0.2,2.2-0.6,3.2C476.1,24.9,472.5,27,467.6,27 M467.7,29.5c6.3,0,10.9-2.8,12.5-7.2
      c0.5-1.3,0.7-2.7,0.7-4.1s-0.2-2.8-0.7-4.1c-1.6-4.5-6.3-7.2-12.5-7.2c-6.3,0-10.9,2.8-12.5,7.2c-0.5,1.3-0.7,2.7-0.7,4.1
      s0.2,2.8,0.7,4.1C456.8,26.8,461.4,29.5,467.7,29.5"/>
    <path d="M434.6,9.8h7.9c1.8,0,3.1,0.6,3.7,1.7c0.3,0.5,0.4,1.1,0.4,1.8s-0.2,1.4-0.5,1.9c-0.6,1.1-1.9,1.6-3.7,1.6h-7.9v-7H434.6z
       M449.5,28.9l-8.3-9.9h1c3.2,0,5.6-0.9,6.6-2.7c0.5-0.8,0.7-1.9,0.7-3c0-1.2-0.2-2.3-0.7-3.2c-1.1-1.8-3.2-2.8-6.3-2.8h-10.6v21.6
      h2.8v-9.7h3.3l7.9,9.7C445.9,28.9,449.5,28.9,449.5,28.9z"/>
  </g>
</g>
</svg>
</template>

<script>
export default {
  name: 'SvgLogoCollection'
}
</script>

<style>
</style>
