<template lang="pug">
  //- header
  //- * transition set in <style></style>
  header#app-header.sticky.z-30.top-0.left-0.w-full.md_h-160.md_flex.items-center.justify-between.px-20.transform.hover_blend-none(:class="{'md_max-h-160': intro, 'md_max-h-80': !intro, 'bg-yellow': isCollection, 'bg-teal': !isCollection, 'blend-multiply': blend, '-translate-y-full_-mlogo md_-translate-y-full_-10': tucked, 'opacity-0': !$route.name}", @mouseenter="untuck({ blend: false })")
    //- intro logo
    //- laptop logo
    .flex-1.md_px-20
      //- (logo: mobile)
      .md_hidden.pt-20.mb-25
        template(v-if="$route.name")
          router-link(v-if="!isCollection", to="/")
            svg-logo-mobile.block.h-50
          router-link(v-else, to="/collection")
            svg-logo-collection-mobile.block.h-50
      //- (logo: laptop)
      .hidden.md_block.transform.transition.duration-300.origin-left(:style="introLogoTransform")
        template(v-if="$route.name")
          router-link(v-if="!isCollection", to="/")
            svg-logo.block.h-14.lg_h-18(ref="logo")
          router-link(v-else, to="/collection")
            svg-logo-collection.block.h-logo-coll-sm.lg_h-logo-coll-md(style="transform: translateY(-8%)")
    //- menu right
    .flex-1.xl_flex-none.xl_w-4x12.pb-20.md_pb-0.md_px-20.text-20.xl_text-24.flex.justify-between.md_justify-end.xl_justify-between.transition.duration-500.delay-500(:class="{'opacity-0 pointer-events-none': intro}")
      //- (links)
      .flex.items-top.h-20(v-show="!searchVisible")
        //- TODO use globals
        router-link.text-14.md_text-16.xl_text-18.mr-30.md_mr-20.lg_mr-30.border-current.inline-block.btn-theme-text(to="/", :class="{'shadow-underline': isSec('programme') && $route.params.page !== 'about'}")
          | Programme

        .link.text-14.md_text-16.xl_text-18.mr-30.md_mr-20.lg_mr-30.border-current.inline-block.btn-theme-text
          div(@mouseleave='showCollectionDropdown = false', :class='{ "h-50": showCollectionDropdown }')
            .hover(@mouseenter='showCollectionDropdown = true')
              router-link(to="/collection", :class="{'_shadow-underline': isSec('collection')}") Collection

            .sub-menu(v-if='showCollectionDropdown', :class="{ 'bg-yellow': isCollection, 'bg-teal': !isCollection, 'blend-multiply': blend }").absolute.-mx-12.mt-22.md_mt-2.min-w-120
              ul.px-12.py-8
                li.py-6.md_py-4
                  a(href='/collection' data-heading='Artists', , @click='showCollectionDropdown = false') Artists
                li.py-6.md_py-4
                  a(href='/collection/loans' data-heading='Loans', , @click='showCollectionDropdown = false') Loans
                li.py-6.md_py-4
                  a(href='/collection/loans/borrow' data-heading='Borrow', , @click='showCollectionDropdown = false') Borrow

        .link.text-14.md_text-16.xl_text-18.mr-30.md_mr-20.lg_mr-30.border-current.inline-block.btn-theme-text
          div(@mouseleave='showAboutDropdown = false', :class='{ "h-50": showAboutDropdown }')
            .hover(@mouseenter='showAboutDropdown = true')
              router-link(to="/about") About

            .sub-menu(v-if='showAboutDropdown', :class="{ 'bg-yellow': isCollection, 'bg-teal': !isCollection, 'blend-multiply': blend }").absolute.-mx-12.mt-22.md_mt-2
              ul.px-12.py-8
                li.py-6.md_py-4
                  a(href='/about#about' data-heading='About', @click='showAboutDropdown = false') About
                li.py-6.md_py-4
                  a(href='/about#team' data-heading='Team', , @click='showAboutDropdown = false') Team
                li.py-6.md_py-4
                  a(href='/about#board' data-heading='Board', , @click='showAboutDropdown = false') Board
                li.py-6.md_py-4
                  a(href='/about#history' data-heading='History', , @click='showAboutDropdown = false') History
                li.py-6.md_py-4
                  a(href='/about#partners' data-heading='Partners', , @click='showAboutDropdown = false') Partners
                li.py-6.md_py-4
                  a(href='/press' data-heading='Press', , @click='showAboutDropdown = false') Press
                li.py-6.md_py-4
                  a(href='/about#opportunities' data-heading='Opportunities', , @click='showAboutDropdown = false') Opportunities
                li.py-6.md_py-4
                  a(href='/about#support' data-heading='Support', , @click='showAboutDropdown = false') Support

      //- (search bar)
      form.flex-1.h-20.flex.items-center.md_pr-15(@submit.prevent="onSearch", v-show="searchVisible")
        input.border-b.w-full.bg-transparent.border-current.text-12.md_text-14.xl_text-18.focus_outline-none.pb-2(ref="search", v-model="searchInput", @blur="onSearchBlur")

      //- search btn
      button.-m-15.ml-0.md_-ml-15.focus_outline-none(@click="toggleSearch")
        .w-50.h-50.flex.items-center.justify-center
          svg-eyeglass.w-20.h-20.transform.scale-105.origin-right(v-show="!searchVisible")
          svg-x.w-17.h-14(v-show="searchVisible")
</template>

<script>
import { mapState } from 'vuex'
import throttle from 'lodash/throttle'
import SvgLogoCollection from '@/components/collection/SvgLogoCollection'
let y = window.pageYOffset
let timer
let dY
export default {
  name: 'AppHeader',
  components: { SvgLogoCollection },
  data () {
    return {
      tucked: false,
      blend: y <= 0,
      lastY: y,
      searchVisible: false,
      searchInput: '',
      styleTransform: '',
      introLogoScale: 2.5 * window.innerWidth / 1440,
      introLogoX: (105 - 40) * window.innerWidth / 1440,
      showCollectionDropdown: false,
      showAboutDropdown: false
    }
  },
  computed: {
    ...mapState({
      intro: 'siteIntro'
    }),
    isCollection () {
      return this.$route.name?.startsWith('collection')
    },
    introLogoTransform () {
      return this.intro && {
        '--transform-scale-x': this.introLogoScale,
        '--transform-scale-y': this.introLogoScale,
        '--transform-translate-x': this.introLogoX + 'px'
      }
    },
    isOpaqueTop () {
      return this.$route.params.page === 'about'
    }
  },
  methods: {
    isSec (name) {
      return this.$route.name?.startsWith(name)
    },
    onSearch: throttle(function () {
      if (this.searchInput.length) {
        // term = kb(this.searchInput).replaceAll('-', '+')
        const term = encodeURIComponent(this.searchInput)
        this.$router.push({ name: 'programme-search', params: { term } })
      }
    }, 1000),
    toggleSearch () {
      return this.searchVisible ? this.closeSearch() : this.openSearch()
    },
    openSearch () {
      this.searchVisible = true
    },
    closeSearch () {
      this.searchVisible = false
    },
    onSearchBlur () {
      // delay close so no click conflict on close btn
      setTimeout(() => {
        this.searchInput = ''
        this.closeSearch()
      }, 300)
    },
    onScroll: throttle(function () {
      y = window.pageYOffset
      if (y <= 0) {
        this.untuck({ blend: !this.isOpaqueTop })
      } else if (y < this.lastY) {
        dY += Math.abs(y - this.lastY)
        if (dY >= 150) { // account for throttle!
          this.untuck({ blend: false })
          dY = 0
        }
      } else if (y > this.$el.offsetHeight) {
        dY = 0
        this.tuck()
      }
      this.lastY = y
    }, 50),
    tuck () {
      clearTimeout(timer)
      this.tucked = true
      timer = setTimeout(() => {
        this.blend = true
      }, 50) // after anim
    },
    untuck ({ blend }) {
      clearTimeout(timer)
      this.tucked = false
      this.blend = blend ?? this.blend
    }
  },
  watch: {
    searchVisible (vis) {
      if (vis) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },
    '$route' () {
      if (this.isOpaqueTop && window.pageYOffset === 0) {
        this.untuck({ blend: false })
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)

    setTimeout(() => {
      this.$store.commit('END_INTRO')
    }, 1500)
  }
}
</script>

<style>
#app-header{
  transition: opacity 200ms 200ms, background 200ms, transform 200ms, max-height 300ms;
}
</style>
