<template lang="pug">
  .sticky.z-60.bottom-0.left-0.w-full.bg-pink.blend-multiply.md_hover_blend-none.flex.items-center.px-20.md_px-40.min-h-45.py-10(v-if="render && text")
    rich-text.text-11.mr-30.underline-links(v-html="text")
    button(@click="accept")
      btn-boxed(size="small") Accept and Close
</template>

<script>
import { mapState } from 'vuex'
import { bootstrap } from 'vue-gtag'
export default {
  name: 'CookieBanner',
  data () {
    return {
      render: undefined
    }
  },
  computed: {
    ...mapState({
      text: state => state.globals.cookieBannerText
    })
  },
  methods: {
    init () {
      const accepted = !isExpired()
      this.render = !accepted
      if (accepted) {
        this.enableAnalytics()
      } else {
        // expired
        localStorage.removeItem('cookies_accepted')
      }
    },
    accept () {
      localStorage.setItem('cookies_accepted', new Date())
      this.enableAnalytics()
      this.render = false
    },
    enableAnalytics () {
      bootstrap().then(gtag => {
        // send initial pageview since delayed
        this.$gtag.pageview({
          page_title: document.title,
          page_path: this.$route.path,
          page_location: window.location.href
        })
      })
    }
  },
  created () {
    this.init()
  }
}

const duration = 1000 * 60 * 60 * 24 * 30 * 6 // 6 months

function isExpired () {
  const date = localStorage.getItem('cookies_accepted')
  if (!date) return true
  const expired = new Date().getTime() - new Date(date).getTime() > duration
  return expired
}

</script>

<style>
</style>
