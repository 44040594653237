import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@/router'

const id = process.env.VUE_APP_GOOGLE_ANALYTICS_ID
if (id) {
  Vue.use(VueGtag, {
    config: { id },
    bootstrap: false,
    params: {
      // client_id: visitorId,
      anonymize_ip: true
      // client_storage: 'none' // no cookie
    },
    pageTrackerTemplate (to) {
      const dflt = 'The Roberts Institute of Art'
      const title = document.title === dflt ? to.name : document.title
      return {
        page_title: title, // 'amazing page',
        page_path: to.path,
        page_location: window.location.href
      }
    }
  }, router)
} else {
  console.warn('Missing environment variable: VUE_APP_GOOGLE_ANALYTICS_ID')
}
