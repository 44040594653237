<template lang="pug">
  .image-asset-caption.bold-is-teal
    //- (caption)
    template(v-if="caption")
      div(v-if="captionCustom", v-html="captionCustom")
      div(v-else-if='image.assetCaption', v-html="image.assetCaption")
    //- crediting
    template(v-if="image.courtesy && image.courtesy.length") Courtesy {{ image.courtesy }}
    template(v-if="image.copyright && image.copyright.length")
      span(v-html="separator", v-if="image.courtesy && image.courtesy.length")
      | © {{ image.copyright }}
    template(v-if="image.photoCredit && image.photoCredit.length")
      span(v-html="separator")
      | Photo: {{ image.photoCredit }}
</template>

<script>
export default {
  name: 'ImageAssetCaption',
  props: {
    image: Object,
    separator: { type: String, default: ' ' },
    caption: { type: Boolean, default: false },
    captionCustom: { type: String, default: undefined }
  }
}
</script>

<style>
</style>
