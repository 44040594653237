<template lang="pug">
  button.btn-read-more.focus_outline-none.text-14.md_text-18.flex.items-center(@click="more = !more")
    | {{before}} {{ more ? 'Less' : 'More' }} {{after}}
    <svg style="height: 0.72em" :class="['ml-15 transform', {'rotate-180': more}]" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
      <path d="M18 3.19429L18 0L9 10.3257L0 -7.86805e-07L-1.39627e-07 3.19429L9 13L18 3.19429Z" fill="black"/>
    </svg>
</template>

<script>
export default {
  name: 'BtnReadMore',
  props: {
    before: { type: String, default: 'Read' },
    after: { type: String, default: '' }
  },
  data () {
    return {
      more: false
    }
  }
}
</script>

<style>
</style>
