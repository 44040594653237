<template lang="pug">
  .animate-push-on-panel.md_transform.transition.duration-200(:class="{'md_translate-x-2x12': panelIsOpen}")
    slot
</template>

<script>
export default {
  name: 'AnimatePushOnPanel',
  data () {
    return {
      panelIsOpen: false
    }
  },
  mounted () {
    this.$root.$on('panelOpen', () => { this.panelIsOpen = true })
    this.$root.$on('panelClose', () => { this.panelIsOpen = false })
  }
}
</script>

<style>
</style>
