<template lang="pug">
  <svg viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <path d="M27 14.5L0.749999 28.7894L0.75 0.21058L27 14.5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgPlayTriangle'
}
</script>

<style>
</style>
