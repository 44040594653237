<template lang="pug">
  .box-link.flex.border.items-center.border-current.leading-none.whitespace-no-wrap(:class="clss")
    slot
</template>

<script>
export default {
  name: 'BoxLink',
  props: {
    size: { type: String, default: 'medium' },
    hoverRound: String,
    active: Boolean
  },
  computed: {
    clss () {
      return {
        'text-12 h-22 md_h-30 px-5 md_px-15': this.size === 'medium',
        'text-10 h-22 md_h-25 px-6 md_px-12': this.size === 'smedium',
        'text-9 lg_text-10 h-18 md_h-25 px-5 md_px-12 pb-1 md_pb-0': this.size === 'small',
        'lg_hover_rounded-full': this.hoverRound,
        'text-teal rounded-full': this.active
      }
    }
  }
}
</script>

<style>
/* can't animate ?? */
/*.box-link.lg_hover_rounded-full{
  transition: border-radius 1s;
}*/
</style>
