<template lang="pug">
  nav.panel-left
    //- panel layer
    .panel-left__main.absolute.z-20.top-0.left-0.w-full.h-full.md_px-20.flex.md_pointer-events-none(:class="{ 'pointer-events-none': !overlayClickable}", @click.self="close")

      //- panel (fill parent via .flex, awkward width)
      .w-1x2.md_w-2x12.flex.md_-ml-20.transform.duration-200.relative(:class="{'-translate-x-full_-10': collapsed}")
        //- scroll/opaque area
        .absolute.overlay.flex.pt-160.md_pt-120.pointer-events-auto(@mouseleave="close")
          //- (opacity layer)
          .absolute.overlay.bg-white.transition(:class="{'opacity-0 delay-200': collapsed || keepOpenLnd, 'duration-200': !collapsed}")

          //- content (hide on intro)
          .relative.z-10.flex.items-start.w-full.transition.duration-700(:class="{'opacity-0': siteIntro}")
            //- sticky upper menu
            //- * ios black line bug related to non-static position...
            div.sticky.top-160.md_top-120.ml-20.md_ml-20.lg_ml-40.text-14.md_text-16.xl_text-18.children-mt-lh
              slot

            //- footer links
            .absolute.bottom-0.left-0.pl-20.pb-20.md_pl-20.lg_pl-40.md_pb-40
              app-footer-menu(slot="footer", socialClasses="hidden md_block")

    //- color overlay layer
    //- fixed above so covers header
    .panel-left__color.fixed.z-40.w-full.top-0.left-0.w-full.h-full.pointer-events-none.flex.blend-multiply.md_px-20
      //- extra trigger area
      button.w-20.md_w-30.absolute.top-0.left-0.h-full.pointer-events-auto(@mouseenter="open", @click="open", aria-label="Open Menu")

      //- color strip that slides with main panel
      .w-1x2.md_w-2x12.md_-ml-20.transform.duration-200(:class="[bg, {'-translate-x-full_-10': collapsed}]")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
let timer
export default {
  name: 'PanelLeft',
  props: {
    // collapsed: { type: Boolean, default: false },
    bg: { type: String, default: 'bg-yellow' },
    keepOpen: { type: Boolean, default: true }
  },
  data () {
    return {
      collapsed: false,
      blend: false,
      overlayClickable: false
      // hover: false
    }
  },
  computed: {
    ...mapState(['siteIntro']),
    ...mapGetters(['is', 'bkpt']),
    keepOpenLnd () {
      return this.keepOpen && this.bkpt === 'md'
    }
  },
  methods: {
    open () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.$emit('panelOpen')
        this.$root.$emit('panelOpen')
        this.collapsed = false
        this.blend = false
        this.overlayClickable = true
      }, 100)
    },
    close (e) {
      clearTimeout(timer)
      if (!this.keepOpenLnd) {
        this.$emit('panelClose')
        this.$root.$emit('panelClose')
        this.collapsed = true
        timer = setTimeout(() => {
          if (this.collapsed) {
            this.blend = true
          }
          // overlay clickable (ios captures too fast)
          timer = setTimeout(() => { this.overlayClickable = false }, 300)
        }, 190) // after anim !
      }
    }
  },
  created () {
    if (!this.keepOpenLnd) {
      this.close()
    }
  },
  watch: {
    keepOpenLnd (open) {
      return open ? this.open() : this.close()
    },
    '$route' () {
      if (!this.is('md')) {
        this.close()
      }
    }
  }
}
</script>

<style>
</style>
