const imgCaption = `
  assetCaption
  courtesy
  copyright
  photoCredit
`
export default (caption = false) => `
  kind
  url
  ... on images_Asset {
    altText
    width
    height
    ${caption ? imgCaption : ''}
  }
  ... on videos_Asset {
    videoPoster {
      url
    }
  }
`
