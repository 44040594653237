import Vue from 'vue'
import Vuex from 'vuex'
import { queryGlobals, querySingle, queryCollection, queryProgramme, queryProgrammeEntry, queryPress, queryPressEntry } from '@/graphql/queries'
import { resizeCloudinary } from '@/components/RespImg'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteIntro: window.location.pathname === '/',
    globals: {},
    singles: {},
    collection: [],

    winW: window.innerWidth
  },
  getters: {
    is: (state) => (ctx) => {
      return ctx === 'md' ? state.winW > 900 // match tailwind.config.js screens
        : false
    },
    bkpt (state) {
      return state.winW >= 900 ? 'md'
        : 'xs'
    },
    dateSpan: () => (start, end, separator = '–') => {
      let date
      start = start && new Date(start)
      if (start) {
        end = end && new Date(end) !== 'Invalid Date' ? new Date(end) : false

        const year = start.getFullYear()
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

        date = `${start.getDate()}`

        if (!end) {
          // year = year === new Date().getFullYear() ? '' : ` ${year}`
          date += ` ${months[start.getMonth()]} ${year}`
          return date
        } else {
          const sameYear = end.getFullYear() === start.getFullYear()
          const sameMonth = end.getMonth() === start.getMonth()

          // same year ?
          if (sameYear) {
            // year = year === new Date().getFullYear() ? '' : ` ${year}`
            if (sameMonth) {
              date += ` ${months[end.getMonth()]}${separator}${end.getDate()} ${months[end.getMonth()]} ${year}`
            } else {
              date += ` ${months[start.getMonth()]}${separator}${end.getDate()} ${months[end.getMonth()]} ${year}`
            }
          } else {
            // diff years
            date += ` ${months[start.getMonth()]} ${start.getFullYear()}${separator}${end.getDate()} ${months[end.getMonth()]} ${end.getFullYear()}`
          }
        }
      }
      return date
    },
    meta: state => ({ title, descrip, img }) => {
      const meta = []
      // defaults
      const siteTitle = 'The Roberts Institute of Art'
      const siteDescrip = state.globals.metaDescription
      const siteImg = state.globals.metaImage && state.globals.metaImage[0]?.url
      // custom
      // title = title ? `${title} | ${siteTitle}` : siteTitle
      descrip = descrip || siteDescrip
      img = img || siteImg
      img = img && resizeCloudinary(img, [1024], false)
      // add
      meta.push({ property: 'og:title', content: title })
      meta.push({ property: 'og:site_name', content: siteTitle })
      meta.push({ property: 'og:type', content: 'website' })
      meta.push({ name: 'description', content: descrip })
      meta.push({ property: 'og:description', content: descrip })
      if (img) meta.push({ property: 'og:image', content: img })
      //
      // meta.push({ property: 'og:url', content: ##ADDCANNONICAL## })
      return meta
    }
  },
  mutations: {
    SET_GLOBALS (state, globals) {
      state.globals = globals
    },
    ADD_SINGLE (state, { section, entry }) {
      state.singles[section] = entry
      state.singles = { ...state.singles } // so vue reacts to data update
    },
    SET_COLLECTION (state, entries) {
      state.collection = entries
    },
    END_INTRO (state) {
      state.siteIntro = false
    },
    SET_WIN_W (state) {
      state.winW = window.innerWidth
    }
  },
  actions: {
    async getGlobals ({ state, commit }) {
      const resp = await api(queryGlobals)
      const globals = resp.data?.globalSets[0] // only one global right now
      if (globals) commit('SET_GLOBALS', globals)
    },
    async getSingle ({ state, commit }, section) {
      // saved?
      if (state.singles[section]) return state.singles[section]
      // fetch
      const resp = await api(querySingle, { section })
      const entry = resp.data?.entries[0]
      if (entry) commit('ADD_SINGLE', { section, entry })
      return entry
    },
    async getCollection ({ state, commit }) {
      if (state.collection.length) return state.collection
      const resp = await api(queryCollection)
      commit('SET_COLLECTION', resp.data.entries)
    },

    async getPressEntries (_, { section, orderBy = 'dateStart DESC, postDate DESC', limit = 20, offset }) {
      // const programmeFull = ['exhibitions', 'performances', 'podcasts', 'residencies', 'projects']
      const variables = {
        section: section,
        orderBy,
        limit,
        offset
      }
      const resp = await api(queryPress, variables)
      // console.log('getPressEntries', resp)
      return resp?.data.entries
    },

    async getPressEntry (_, slug) {
      const resp = await api(queryPressEntry, { slug })
      console.log('rest', resp)
      return resp.data?.entry
    },

    async getProgrammeEntries (_, { section, relatedToTags, orderBy = 'dateStart DESC, postDate DESC', dateStart, dateEnd, limit = 20, offset }) {
      const programmeFull = ['exhibitions', 'performances', 'podcasts', 'residencies', 'projects']
      const variables = {
        section: section || programmeFull,
        orderBy,
        relatedToTags,
        dateStart,
        dateEnd,
        limit,
        offset
      }
      const resp = await api(queryProgramme, variables)
      return resp?.data.entries
    },

    async getProgrammeEntry (_, slug) {
      const resp = await api(queryProgrammeEntry, { slug })
      console.log('rest', resp)
      return resp.data?.entry
    }
  },
  modules: {
  }
})

// helpers

// Craft CMS GraphQL API
export const api = async (query, variables) => {
  try {
    let resp = await fetch(process.env.VUE_APP_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // preview token ?
        'x-craft-token': new URL(window.location.href).searchParams.get('token')
      },
      body: JSON.stringify({ query, variables })
    })
    resp = await resp.json()
    if (resp.errors) {
      console.error('API ERRORS', resp.errors)
      return Promise.reject(new Error('API'))
    }
    return resp
  } catch (e) {
    console.error('API Error:', e)
  }
}
