import imageVideoClip from '@/graphql/common/imageVideoClip'

export default `
  performanceBody {
    ... on performanceBody_textAndImageSquare_BlockType {
      type:__typename
      text
      image {
        ${imageVideoClip()}
      }
      layout
    }
    ... on performanceBody_accentImage_BlockType {
      type:__typename
      image {
        ${imageVideoClip()}
      }
      align
    }
    ... on performanceBody_image_BlockType {
      type:__typename
      image {
        ${imageVideoClip()}
      }
    }
    ... on performanceBody_bodyText_BlockType {
      type:__typename
      text
    }
    ... on performanceBody_vimeoYoutubePlayer_BlockType {
      type: __typename
      vimeoYoutubeEmbed
    }
    ... on performanceBody_videoPlayer_BlockType {
      type: __typename
      videoAsset {
        ... on videos_Asset {
          url
          videoPoster {
            ... on images_Asset {
              url
            }
          }
        }  
      }
    }
    ... on performanceBody_callToAction_BlockType {
      type: __typename
      text
      flexLink {
        url
        target
      }
      boxLink {
        text
        url
        target
      }
    }
    ... on performanceBody_embed_BlockType {
      type: __typename
      embedCode
    }
  }
`
